const FRACTION_MAP = {
  125: "⅛",
  25: "¼",
  5: "½",
  75: "¾"
};

const UNIT_DISPLAY_MAP = {
  oz: "OZ",
  part: "Part",
  none: "",
  dash: "Dash",
  barspoon: "Barspoon"
};

export const decimalToFraction = (number) => {
  const numberString = number.toString();
  const [integer, decimal] = numberString.split(".");
  if (!decimal) return numberString;

  const fraction = FRACTION_MAP[decimal];
  // decimal does not fit neatly into a fraction - fall back to decimal
  if (!fraction) return numberString;

  const integerString = integer !== "0" ? integer : "";

  return [integerString, fraction].join(" ");
};

export const formatUnitForDisplay = (unit) => {
  return UNIT_DISPLAY_MAP[unit];
};
