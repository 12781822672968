import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  getRecipeFormValues,
  clearRecipeFormValues,
  updateRecipe,
  deleteRecipe
} from "../../../action_creators/recipe_creators";
import PageLoading from "../../utils/PageLoading";
import TextField from "../../form/TextField";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  title: Yup.string().required("Required")
});

class RecipeEdit extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentDidMount() {
    const {
      getRecipeFormValues,
      match: {
        params: { id }
      }
    } = this.props;
    return getRecipeFormValues(id);
  }

  componentWillUnmount() {
    const { clearRecipeFormValues } = this.props;
    return clearRecipeFormValues();
  }

  onSubmit(formValues) {
    const {
      updateRecipe,
      match: {
        params: { id }
      }
    } = this.props;
    return updateRecipe({ formValues, id });
  }

  onDeleteClick() {
    const {
      deleteRecipe,
      match: {
        params: { id }
      }
    } = this.props;
    deleteRecipe(id);
  }

  $deleteBtn() {
    return (
      <button
        type="button"
        className="inline-block p-3 text-center text-red-500 hover:text-white transition rounded-md hover:shadow-lg hover:bg-red-600 focus:outline-none"
        onClick={this.onDeleteClick}
      >
        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    );
  }

  $cancelBtn() {
    const { recipe } = this.props;
    return (
      <Link to={`/cocktails/${recipe.id}`} className="btn-secondary">
        Cancel
      </Link>
    );
  }

  $loadableContent() {
    const { recipe, loading } = this.props;
    if (!recipe || loading) return <PageLoading />;

    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          title: recipe.title
        }}
        validationSchema={DisplayingErrorMessagesSchema}
      >
        {({ isSubmitting }) => (
          <Form className="mt-8 space-y-3">
            <div className="flex justify-between align-middle">
              <h3 className="text-xl">Edit Cocktail Recipe</h3>
              {this.$deleteBtn()}
            </div>
            <TextField label="Recipe Name" name="title" placeholder="Recipe Name" />
            <div className="flex justify-end">
              {this.$cancelBtn()}
              <button type="submit" disabled={isSubmitting} className="btn-blue-primary ml-2">
                Save
              </button>
            </div>
          </Form>
        )}
        {/* <RecipeVersionComponent recipeId={recipe.id} /> */}
      </Formik>
    );
  }

  render() {
    return (
      <div id="recipe-detail" className="max-w-7xl mx-auto pb-12 pt-6 px-4 sm:px-6 lg:px-8">
        <div className="border shadow p-6">{this.$loadableContent()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({
  formValues: {
    recipeEdit: { data, loading }
  }
}) => {
  return {
    recipe: data,
    loading
  };
};

export default connect(mapStateToProps, { getRecipeFormValues, clearRecipeFormValues, updateRecipe, deleteRecipe })(
  RecipeEdit
);
