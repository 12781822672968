import { GET_FORM_VALUES_SUCCESS, LOAD_FORM_VALUES, CLEAR_FORM_VALUES } from "../action_types";

const DEFAULT_CONFIG = { data: null, error: false, loading: false };

const FORM_VALUES_CONFIG = [
  {
    name: "recipeEdit"
  }
];

const initialState = {};
FORM_VALUES_CONFIG.forEach((config) => {
  initialState[config.name] = {
    ...DEFAULT_CONFIG
  };
});

const formValuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FORM_VALUES_SUCCESS:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          ...action.payload.response,
          error: false,
          loading: false
        }
      };
    case LOAD_FORM_VALUES:
      return {
        ...state,
        [action.payload.name]: { ...state[action.payload.name], error: false, loading: true }
      };
    case CLEAR_FORM_VALUES:
      return {
        ...state,
        [action.payload.name]: {
          ...DEFAULT_CONFIG
        }
      };
    default:
      return state;
  }
};

export default formValuesReducer;
