import React from "react";
import { connect } from "react-redux";
import RecipeVersionFormWrapper from "./RecipeVersionFormWrapper";
import RecipeVersionList from "./RecipeVersionList";
import { getRecipeVersions, VERSIONS_LIST_NAME } from "../../../../action_creators/recipe_creators";

class RecipeVersionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewFormModal: false,
      versionId: null
    };
    this.onAddBtnClick = this.onAddBtnClick.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.onVersionClick = this.onVersionClick.bind(this);
  }

  componentDidMount() {
    const { getRecipeVersions, recipeId } = this.props;
    getRecipeVersions(recipeId);
  }

  onAddBtnClick(_ev) {
    this.setState({ showNewFormModal: true, versionId: null });
  }

  modalClose(_ev) {
    this.setState({ showNewFormModal: false, versionId: null });
  }

  onVersionClick(versionId) {
    this.setState({ showNewFormModal: true, versionId });
  }

  $addBtn() {
    const { showNewFormModal } = this.state;
    if (showNewFormModal) return null;
    return (
      <button onClick={this.onAddBtnClick} className="btn-blue-primary">
        Add New
      </button>
    );
  }

  newVersionForm() {
    const { showNewFormModal, versionId } = this.state;
    const { recipeId } = this.props;
    if (!showNewFormModal) return null;
    return <RecipeVersionFormWrapper modalClose={this.modalClose} versionId={versionId} recipeId={recipeId} />;
  }

  $header() {
    return (
      <div className="border-t-2 border-gray-100 mt-6 pt-4 flex justify-between">
        <h3 className="text-xl text-gray-600 font-semibold">Recipe Versions</h3>
        {this.$addBtn()}
      </div>
    );
  }

  render() {
    const { recipeVersions } = this.props;
    return (
      <div id="recipe-version-component">
        {this.$header()}
        {this.newVersionForm()}
        <RecipeVersionList recipeVersions={recipeVersions} onVersionClick={this.onVersionClick} />
      </div>
    );
  }
}

const mapStateToProps = ({ lists: { [VERSIONS_LIST_NAME]: recipeVersions } }) => {
  return { recipeVersions };
};

export default connect(mapStateToProps, { getRecipeVersions })(RecipeVersionComponent);
