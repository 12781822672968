import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { dbDateStringToDisplay } from "../../../utils/date_utils";
import { getRecipe, clearRecipe } from "../../../action_creators/recipe_creators";
import RecipeVersionComponent from "./recipe_version/RecipeVersionComponent";
import PageLoading from "../../utils/PageLoading";

class RecipeDetail extends React.Component {
  componentDidMount() {
    const {
      getRecipe,
      match: {
        params: { id }
      }
    } = this.props;
    return getRecipe(id);
  }

  componentWillUnmount() {
    const { clearRecipe } = this.props;
    return clearRecipe();
  }

  $attribute({ title, value }) {
    return (
      <div className="detail-attribute mt-2">
        <span className="font-medium">{title}: </span>
        <span className="font-normal">{value}</span>
      </div>
    );
  }

  $editBtn() {
    const { recipe } = this.props;
    return (
      <Link to={`/cocktails/${recipe.id}/edit`} className="btn-blue-primary">
        Edit
      </Link>
    );
  }

  $loadableContent() {
    const { recipe, loading } = this.props;
    if (!recipe || loading) return <PageLoading />;

    return (
      <React.Fragment>
        <div className="flex justify-between">
          <h3 className="text-xl">{recipe.title}</h3>
          {this.$editBtn()}
        </div>
        {this.$attribute({ title: "Created", value: dbDateStringToDisplay(recipe.insertedAt) })}
        {this.$attribute({ title: "Last Updated", value: dbDateStringToDisplay(recipe.updatedAt) })}
        <RecipeVersionComponent recipeId={recipe.id} />
      </React.Fragment>
    );
  }

  render() {
    return (
      <div id="recipe-detail" className="max-w-7xl mx-auto pb-12 pt-6 px-4 sm:px-6 lg:px-8">
        <div className="border shadow p-6">{this.$loadableContent()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({
  details: {
    recipeShow: { data, loading }
  }
}) => {
  return {
    recipe: data,
    loading
  };
};

export default connect(mapStateToProps, { getRecipe, clearRecipe })(RecipeDetail);
