export default function snakeCaseKeys(obj) {
  if (!obj || typeof obj != "object") return obj;
  if (Array.isArray(obj)) {
    return obj.map((el) => snakeCaseKeys(el));
  }
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    newObj[uncamelize(key)] = snakeCaseKeys(value);
  });
  return newObj;
}

function uncamelize(string) {
  const camelSplitter = /(.)([A-Z0-9]+)/g;
  return string.replace(camelSplitter, function (m, previous, uppers) {
    return `${previous}_${uppers.toLowerCase().split("").join("_")}`;
  });
}
