import React from "react";
import GenericItemList from "./GenericItemList";
import AddUserIngredient from "./AddUserIngredient";

class AddRecipeItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { itemType: null };
    this.onItemClick = this.onItemClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  setItemType(key) {
    this.setState({ itemType: key });
  }

  onItemClick({ ingredientId, name }) {
    const { fieldArrayHelpers, toggleAddItemOverlay } = this.props;

    fieldArrayHelpers.push({ measurement: 1, measurementType: "oz", ingredientId, name, initialAdd: true });
    return toggleAddItemOverlay();
  }

  $itemButton({ name, itemType }) {
    return (
      <button type="button" onClick={() => this.setItemType(itemType)} className="btn-secondary w-full ml-0 mt-3">
        {name}
      </button>
    );
  }

  $buttons() {
    const { itemType } = this.state;
    if (itemType) return null;

    return (
      <>
        {this.$itemButton({ name: "My Inventory", itemType: "inventory" })}
        {this.$itemButton({ name: "Generic Spirits", itemType: "genericSpirit" })}
        {this.$itemButton({ name: "Fresh Juice", itemType: "juice" })}
        {this.$itemButton({ name: "Syrups & Sweeteners", itemType: "syrupsSweeteners" })}
        {/* {this.$itemButton({ name: "Ingredient Search", itemType: "search" })} */}
      </>
    );
  }

  onCancelClick() {
    const { toggleAddItemOverlay } = this.props;
    this.setItemType(null);
    return toggleAddItemOverlay();
  }

  $cancelBtn() {
    return (
      <button type="button" onClick={this.onCancelClick} className="btn-secondary w-full ml-0 mt-3">
        Cancel
      </button>
    );
  }

  $typeComponent() {
    const { itemType } = this.state;

    switch (itemType) {
      case "juice":
        return <GenericItemList title="Fresh Juice" ingredientType="juice" onItemClick={this.onItemClick} />;
      case "syrupsSweeteners":
        return (
          <GenericItemList title="Syrups & Sweeteners" ingredientType="sweetener" onItemClick={this.onItemClick} />
        );
      case "inventory":
        return <AddUserIngredient onItemClick={this.onItemClick} />;
      case "genericSpirit":
        return <GenericItemList title="Generic Spirit" isGenericSpirit onItemClick={this.onItemClick} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div id="add-recipe-item" className="mb-3 mt-2 p-2 py-4 lg:p-4 rounded bg-white border shadow">
        {this.$buttons()}
        {this.$typeComponent()}
        {this.$cancelBtn()}
      </div>
    );
  }
}

export default AddRecipeItem;
