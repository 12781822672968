import { useSelector } from "react-redux";

const CONFIG = {
  info: {
    background: "bg-blue-200",
    text: "text-blue-800"
  },
  error: {
    background: "bg-red-200",
    text: "text-red-800"
  },
  warn: {
    background: "bg-yellow-200",
    text: "text-yellow-800"
  }
};

const ToastNotification = () => {
  const { show, message, type } = useSelector(({ toast }) => toast);
  if (!show) return null;
  const { background, text } = CONFIG[type];

  return (
    <div
      className={`absolute right-4 lg:right-8 top-16 z-50 rounded shadow mt-1 py-1 px-6 font-medium ${background} ${text}`}
    >
      {message}
    </div>
  );
};

export default ToastNotification;
