import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { listUserIngredients, LIST_NAME } from "../../../../../action_creators/user_ingredient_creators";
import { groupDataByCategory } from "./add_recipe_item_utils";
import { IngredientGroup } from "./IngredientGroup";

const AddUserIngredient = ({ onItemClick, listUserIngredients, userIngredients: { data, loading } }) => {
  useEffect(() => {
    listUserIngredients();
  }, [listUserIngredients]);

  const $loading = () => (
    <div className="animate-pulse mt-4">
      <div className="h-4 mt-2 bg-gray-300 rounded w-full"></div>
      <div className="h-4 mt-2 bg-gray-300 rounded w-full"></div>
      <div className="h-4 mt-2 bg-gray-300 rounded w-full"></div>
      <div className="h-4 mt-2 bg-gray-300 rounded w-full"></div>
    </div>
  );

  const $noUserIngredients = () => (
    <div className="">
      Aw shucks, it looks like you haven't saved any ingredients to your inventory yet!
      <Link to="/inventory" className="btn-blue-primary mt-3 block w-full">
        Manage Inventory
      </Link>
    </div>
  );

  const $ingredientList = () => {
    if (!data) return $noUserIngredients();

    const groupedData = groupDataByCategory(data, true);
    const sortedKeys = Object.keys(groupedData).sort();

    return sortedKeys.map((groupName) => {
      return (
        <IngredientGroup
          key={groupName}
          onItemClick={onItemClick}
          groupName={groupName}
          categoryData={groupedData[groupName]}
        />
      );
    });
  };

  return (
    <div>
      <div className="p-2 py-3 bg-gray-100 w-full border">
        <h3 className="font-semibold text-center text-gray-700">My Inventory</h3>
      </div>
      {loading ? $loading() : $ingredientList()}
    </div>
  );
};

const mapStateToProps = ({ lists: { [LIST_NAME]: userIngredients } }) => {
  return {
    userIngredients
  };
};

export default connect(mapStateToProps, { listUserIngredients })(AddUserIngredient);
