import { NavLink } from "react-router-dom";

const NavButton = ({ isMobile, path, text, exact, onClick = () => {} }) => {
  const mobileClasses = isMobile ? "block text-base" : "text-sm";

  return (
    <NavLink
      to={path}
      className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium ${mobileClasses}`}
      activeClassName="bg-gray-900 hover:bg-gray-900 text-white"
      exact={exact}
      onClick={onClick}
    >
      {text}
    </NavLink>
  );
};

export default NavButton;
