import { Link } from "react-router-dom";
import styles from "./splash_page.module.css";

const SplashPage = () => (
  <div id="splash-page" className={styles["splash-page"]}>
    <main className={styles.hero}>
      <div className="text-center mx-auto max-w-7xl">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block text-gray-200 xl:inline">An App For </span>
          <span className="block text-gray-200 xl:inline">Curious Cocktail Crafters</span>
        </h1>
        <p className="mt-4 text-base text-gray-200 sm:mt-5 sm:text-lg sm:max-w-xl mx-auto md:mt-5 md:text-xl">
          Libation Library is a cocktail recipe notebook centered around experimentation. Track and compare differences
          across many versions of the same drink and maybe get one step closer to the perfect Negroni.
        </p>
        <div className="mt-8 sm:flex sm:justify-center">
          <Link to="/signup" className="btn-secondary font-semibold text-lg px-5 py-3">
            Get Started
          </Link>
        </div>
      </div>
    </main>
  </div>
);

export default SplashPage;
