export const IngredientGroup = ({ onItemClick, groupName, categoryData }) => {
  const sortedData = categoryData.sort((itemA, itemB) => {
    if (itemA.name < itemB.name) return -1;
    if (itemA.name > itemB.name) return 1;
    return 0;
  });

  const $ingredientImg = ({ ingredient }) => {
    if (!ingredient.publicImage) return null;
    return (
      <div className="w-13 flex-shrink-0 ml-2 p-2 bg-white">
        <img className="max-h-12 max-w-12" src={ingredient.publicImage} alt={ingredient.name} />
      </div>
    );
  };

  const $ingredients = sortedData.map((ingredient) => (
    <button
      key={ingredient.id}
      type="button"
      className="hover:bg-blue-50 w-full text-left font-medium text-gray-800 flex justify-between items-center"
      onClick={() => onItemClick({ name: ingredient.name, ingredientId: ingredient.id })}
    >
      <span className="p-2 py-3">{ingredient.name}</span>
      {$ingredientImg({ ingredient: ingredient })}
    </button>
  ));

  return (
    <div className="" key={groupName}>
      <div className="p-2 py-3 bg-gray-50 w-full border border-b-0 border-t-0">
        <h4 className="font-semibold text-blue-700">{groupName}</h4>
      </div>
      <div className="divide-y divide-light-blue-400 border">{$ingredients}</div>
    </div>
  );
};
