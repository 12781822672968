import { loadList, onGetListSuccess } from "./list_creators";
import api from "../utils/api";

export const LIST_NAME = "userIngredientList";

export const listUserIngredients = () => {
  return (dispatch) => {
    dispatch(loadList(LIST_NAME));

    return api.get("/user_ingredients", {
      on: {
        success: (response) => {
          dispatch(onGetListSuccess({ response, listName: LIST_NAME }));
        }
      }
    });
  };
};

export const saveUserIngredient = ({ ingredientId, onSuccess = () => {} }) => {
  return (_dispatch) => {
    return api.post("/user_ingredients", {
      config: { params: { ingredientId } },

      on: {
        success: (response) => {
          onSuccess(response.data);
        }
      }
    });
  };
};
