import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import LargeModal from "../../../modal/LargeModal";
import { getRecipeVersion, upsertRecipeVersion, getRecipeVersions } from "../../../../action_creators/recipe_creators";
import RecipeVersionForm from "./RecipeVersionForm";

const RecipeVersionFormErrorSchema = Yup.object().shape({
  recipeIngredients: Yup.array().min(1).required("Please add at least 1 ingredient")
});

class RecipeVersionFormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        isFavorite: true
      },
      loading: true
    };
    this.onGetSuccess = this.onGetSuccess.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { versionId, getRecipeVersion, recipeId } = this.props;
    if (versionId) {
      getRecipeVersion({ versionId, recipeId, onSuccess: this.onGetSuccess });
    } else {
      this.setState({ loading: false });
    }
  }

  onGetSuccess(recipeVersion) {
    const recipeIngredients = recipeVersion.recipeIngredients.data.map((ingredient) => {
      return {
        id: ingredient.id,
        ingredientId: ingredient.ingredientId,
        measurement: ingredient.measurement,
        measurementType: ingredient.measurementType,
        name: ingredient.ingredient.name
      };
    });

    this.setState({
      initialValues: {
        notes: recipeVersion.notes,
        recipeIngredients,
        isFavorite: recipeVersion.isFavorite
      },
      loading: false
    });
  }

  onSubmit(formValues) {
    const { upsertRecipeVersion, recipeId, versionId, getRecipeVersions, modalClose } = this.props;
    return upsertRecipeVersion({
      formValues,
      recipeId,
      versionId,
      onSuccess: (response) => {
        console.log(response);
        getRecipeVersions(recipeId);
        modalClose();
      }
    });
  }

  $formLoading() {
    return (
      <div className="p-8 my-3 w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-300 rounded"></div>
              <div className="h-4 bg-gray-300 rounded w-5/6"></div>
            </div>
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 bg-gray-300 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  $bottomActions(isSubmitting) {
    const { modalClose } = this.props;

    return (
      <div className="modal-footer">
        <button
          onClick={modalClose}
          type="button"
          className="ml-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="ml-3 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-blue-700 text-base font-medium text-white hover:bg-blue-800 disabled:opacity-50 focus:outline-none sm:w-auto sm:text-sm"
        >
          Save
        </button>
      </div>
    );
  }

  $form() {
    const { loading, initialValues } = this.state;
    const { recipe } = this.props;
    if (loading) return this.$formLoading();

    return (
      <Formik initialValues={initialValues} onSubmit={this.onSubmit} validationSchema={RecipeVersionFormErrorSchema}>
        {({ values, handleSubmit, isSubmitting, errors, submitCount }) => (
          <form onSubmit={handleSubmit}>
            <RecipeVersionForm recipe={recipe} formikValues={values} errors={errors} submitCount={submitCount} />
            {this.$bottomActions(isSubmitting)}
          </form>
        )}
      </Formik>
    );
  }

  render() {
    const { modalClose } = this.props;

    return (
      <LargeModal modalClose={modalClose}>
        <div id="recipe-version-form">{this.$form()}</div>
      </LargeModal>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    details: {
      recipeShow: { data: recipe }
    }
  } = state;
  return {
    recipe
  };
};

export default connect(mapStateToProps, {
  getRecipeVersion,
  upsertRecipeVersion,
  getRecipeVersions
})(RecipeVersionFormWrapper);
