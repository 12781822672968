import React from "react";
import RecipeVersionItem from "./RecipeVersionItem";

class RecipeVersionList extends React.Component {
  groupRecipeVersions() {
    const { recipeVersions } = this.props;
    return recipeVersions.data.reduce(
      (acc, version) => {
        if (version.isFavorite) {
          acc.favorite = version;
          return acc;
        } else {
          acc.nonFavorites.push(version);
          return acc;
        }
      },
      { favorite: null, nonFavorites: [] }
    );
  }

  render() {
    const { recipeVersions, onVersionClick } = this.props;
    if (recipeVersions.loading || !recipeVersions.data) return null;

    const { favorite, nonFavorites } = this.groupRecipeVersions();

    return (
      <div id="recipe-version-list">
        {favorite && (
          <div className="favorite-recipe mt-3">
            <RecipeVersionItem recipeVersion={favorite} onClick={onVersionClick} showFavoriteIcon />
            <hr className="mt-4" />
          </div>
        )}

        {nonFavorites.map((recipeVersion) => (
          <RecipeVersionItem
            key={recipeVersion.id}
            recipeVersion={recipeVersion}
            onClick={onVersionClick}
            favoriteVersion={favorite}
          />
        ))}
      </div>
    );
  }
}

export default RecipeVersionList;
