import { decimalToFraction, formatUnitForDisplay } from "../../../../utils/measurement_display";

const getCompareClasses = ({ ingredient, favoriteVersion, recipeVersion }) => {
  const classes = { ingredientClass: "", measurementClass: "" };
  if (recipeVersion.isFavorite || !favoriteVersion) return classes;

  const favoriteMatch = favoriteVersion.recipeIngredients.data.find(
    (favoredIngredient) => favoredIngredient.ingredient.id === ingredient.ingredient.id
  );

  // TODO: also consider measurementType
  const measurementMatches = favoriteMatch && favoriteMatch.measurement === ingredient.measurement;

  if (!favoriteMatch) {
    classes.ingredientClass = "no-match bg-red-100";
    return classes;
  } else if (!measurementMatches) {
    classes.measurementClass = "no-match p-1 pr-1 -ml-1 bg-red-100 rounded";
    return classes;
  } else {
    return classes;
  }
};

const $favoriteStar = ({ recipeVersion, showFavoriteIcon }) => {
  if (!showFavoriteIcon || !recipeVersion.isFavorite) return null;

  return (
    <span className="bg-yellow-400 p-1 mb-1 text-sm font-medium rounded shadow text-gray-800 inline-block align-middle">
      <svg className="w-4 h-4 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
      Favorite
    </span>
  );
};

const $ingredient = ({ ingredient, favoriteVersion, recipeVersion }) => {
  const { ingredientClass, measurementClass } = getCompareClasses({ ingredient, favoriteVersion, recipeVersion });

  return (
    <div key={ingredient.id} className="recipe-ingredient mt-2">
      <span className={`p-1 rounded ${ingredientClass}`}>
        <span className={measurementClass}>
          {decimalToFraction(ingredient.measurement)} {formatUnitForDisplay(ingredient.measurementType)}
        </span>{" "}
        {ingredient.ingredient.name}
      </span>
    </div>
  );
};

const RecipeVersionItem = (props) => {
  const {
    recipeVersion: { notes, recipeIngredients, id, isFavorite },
    recipeVersion,
    onClick,
    favoriteVersion,
    showFavoriteIcon
  } = props;

  const itemOnClick = () => {
    if (!onClick) return () => {};
    onClick(id);
  };

  const isFavoriteClasses =
    isFavorite && showFavoriteIcon
      ? "border-yellow-200 border-2 hover:bg-yellow-50"
      : "border-gray-100 border hover:bg-blue-50 hover:border-blue-200";

  return (
    <div
      onClick={itemOnClick}
      className={`${isFavoriteClasses} relative mt-4 p-3 bg-white rounded shadow-md cursor-pointer`}
    >
      {$favoriteStar({ recipeVersion, showFavoriteIcon })}
      <div className="divide-y">
        <div className="pb-2">
          {recipeIngredients.data.map((ingredient) => $ingredient({ ingredient, favoriteVersion, recipeVersion }))}
        </div>
        {notes && <div className="title mr-8 pt-2">{notes}</div>}
      </div>
    </div>
  );
};

export default RecipeVersionItem;
