import { AUTHENTICATION_SUCCESS, AUTHENTICATION_SIGNOUT } from "../action_types";
import { getAuthToken, getCurrentUserFromCookie } from "../action_creators/auth_creators";

const authToken = getAuthToken();
const currentUserCookie = getCurrentUserFromCookie();

const INITIAL_STATE = { isAuthenticated: !!authToken, currentUser: currentUserCookie };

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      return { ...state, isAuthenticated: true, currentUser: action.payload.data.user };
    case AUTHENTICATION_SIGNOUT:
      return { ...state, isAuthenticated: false, currentUser: null };
    default:
      return state;
  }
};

export default authReducer;
