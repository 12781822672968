import { GET_RECIPE_VERSION_SUCCESS, CLEAR_RECIPE_VERSION, LOAD_RECIPE_VERSION } from "../action_types";

const INITIAL_STATE = { data: null, loading: false };

const ingredientSearchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_RECIPE_VERSION_SUCCESS:
      return { data: action.payload.response, loading: false };
    case LOAD_RECIPE_VERSION:
      return { data: null, loading: true };
    case CLEAR_RECIPE_VERSION:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default ingredientSearchReducer;
