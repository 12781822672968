import { Field } from "formik";

const isInErrorState = (meta) => meta.error && meta.touched;

const $errors = ({ meta }) => {
  if (!isInErrorState(meta)) return null;

  return <span className="text-red-700 text-sm">{meta.error}</span>;
};

const $input = ({ meta, input, placeholder, type, field, autoComplete }) => {
  const outlineClass = isInErrorState(meta) ? "border-red-500" : "";

  return (
    <input
      type={type}
      placeholder={placeholder}
      autoComplete={autoComplete}
      className={`mt-2 mb-1 text-base relative w-full border rounded placeholder-gray-400 focus:border-indigo-400 focus:outline-none ${outlineClass}`}
      {...field}
    />
  );
};

const TextField = ({ label, name, placeholder, type = "text", autoComplete = "off" }) => {
  return (
    <Field name={name}>
      {({ input, meta, field }) => (
        <div>
          <label htmlFor={name}>{label}</label>
          {$input({ meta, input, placeholder, type, field, autoComplete })}
          {$errors({ meta })}
        </div>
      )}
    </Field>
  );
};

export default TextField;
