import { push } from "connected-react-router";
import {
  LOAD_DETAIL,
  GET_DETAIL_SUCCESS,
  CLEAR_DETAIL,
  GET_RECIPE_VERSION_SUCCESS,
  LOAD_RECIPE_VERSION,
  CLEAR_RECIPE_VERSION,
  LOAD_FORM_VALUES,
  GET_FORM_VALUES_SUCCESS,
  CLEAR_FORM_VALUES
} from "../action_types";
import api from "../utils/api";
import { loadList, onGetListSuccess } from "./list_creators";
import { showToast } from "./toast_creators";

export const LIST_NAME = "recipeList";
const DETAIL_NAME = "recipeShow";
const FORM_NAME = "recipeEdit";

export const VERSIONS_LIST_NAME = "recipeVersionsList";

const loadRecipeVersion = () => ({
  type: LOAD_RECIPE_VERSION
});

const onGetRecipeVersionSuccess = (response) => ({
  type: GET_RECIPE_VERSION_SUCCESS,
  payload: { response }
});

export const clearRecipeVersion = () => ({
  type: CLEAR_RECIPE_VERSION
});

export const getRecipeVersion = ({ recipeId, versionId, onSuccess = () => {} }) => {
  return (dispatch) => {
    dispatch(loadRecipeVersion());

    return api.get(`/recipes/${recipeId}/versions/${versionId}`, {
      on: {
        success: (response) => {
          onSuccess(response.data);
          dispatch(onGetRecipeVersionSuccess(response.data));
        }
      }
    });
  };
};

export const upsertRecipeVersion = ({ formValues, recipeId, versionId, onSuccess = () => {} }) => {
  return (_dispatch) => {
    if (versionId) {
      return api.put(`/recipes/${recipeId}/versions/${versionId}`, {
        config: { data: formValues },
        on: {
          success: (response) => {
            onSuccess(response.data);
          }
        }
      });
    } else {
      return api.post(`/recipes/${recipeId}/versions`, {
        config: { data: formValues },
        on: {
          success: (response) => {
            onSuccess(response.data);
          }
        }
      });
    }
  };
};

export const listRecipes = () => {
  return (dispatch) => {
    dispatch(loadList(LIST_NAME));

    return api.get("/recipes", {
      on: {
        success: (response) => {
          dispatch(onGetListSuccess({ response, listName: LIST_NAME }));
        }
      }
    });
  };
};

export const getRecipeVersions = (recipeId) => {
  return (dispatch) => {
    dispatch(loadList(VERSIONS_LIST_NAME));

    return api.get(`/recipes/${recipeId}/versions`, {
      on: {
        success: (response) => {
          dispatch(onGetListSuccess({ response, listName: VERSIONS_LIST_NAME }));
        }
      }
    });
  };
};

// ******* recipe detail *******
const loadRecipe = () => ({
  type: LOAD_DETAIL,
  payload: { name: DETAIL_NAME }
});

const onGetSuccess = (response) => ({
  type: GET_DETAIL_SUCCESS,
  payload: { name: DETAIL_NAME, response }
});

export const clearRecipe = () => ({
  type: CLEAR_DETAIL,
  payload: { name: DETAIL_NAME }
});

export const getRecipe = (id) => {
  return (dispatch) => {
    dispatch(loadRecipe());

    return api.get(`/recipes/${id}`, {
      on: {
        success: (response) => {
          dispatch(onGetSuccess(response));
        }
      }
    });
  };
};

export const deleteRecipe = (id) => {
  return (dispatch) => {
    return api.delete(`/recipes/${id}`, {
      on: {
        success: (_response) => {
          dispatch(push("/cocktails"));
          dispatch(showToast({ message: "Recipe Deleted!" }));
        }
      }
    });
  };
};

// ******* recipe form *******
const loadRecipeFormValues = () => ({
  type: LOAD_FORM_VALUES,
  payload: { name: FORM_NAME }
});

const onGetFormValuesSuccess = (response) => ({
  type: GET_FORM_VALUES_SUCCESS,
  payload: { name: FORM_NAME, response }
});

export const clearRecipeFormValues = () => ({
  type: CLEAR_FORM_VALUES,
  payload: { name: FORM_NAME }
});

export const getRecipeFormValues = (id) => {
  return (dispatch) => {
    dispatch(loadRecipeFormValues());

    return api.get(`/recipes/${id}`, {
      on: {
        success: (response) => {
          dispatch(onGetFormValuesSuccess(response));
        }
      }
    });
  };
};

export const updateRecipe = ({ id, formValues, onSuccess = () => {} }) => {
  return (dispatch) => {
    return api.put(`/recipes/${id}`, {
      config: { data: formValues },
      on: {
        success: (response) => {
          onSuccess(response);
          dispatch(push(`/cocktails/${id}`));
          dispatch(showToast({ message: "Recipe Updated!" }));
        }
      }
    });
  };
};

export const createRecipe = ({ formValues, onSuccess = () => {} }) => {
  const { title, notes, isFavorite, recipeIngredients } = formValues;

  const newRecipe = {
    title,
    recipeVersion: {
      notes,
      isFavorite,
      recipeIngredients
    }
  };

  return (dispatch) => {
    return api.post(`/recipes`, {
      config: { data: newRecipe },
      on: {
        success: (response) => {
          onSuccess(response);
          dispatch(push(`/cocktails/${response.data.id}`));
          dispatch(showToast({ message: "Recipe Created!" }));
        }
      }
    });
  };
};
