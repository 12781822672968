import { SHOW_TOAST, CLEAR_TOAST } from "../action_types";

const INITIAL_STATE = { show: false, message: null, type: "info" };

const toastReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return { show: true, message: action.payload.message, type: action.payload.type };
    case CLEAR_TOAST:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default toastReducer;
