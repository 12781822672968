import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { push } from "connected-react-router";
import { showToast } from "../../action_creators/toast_creators";
import { signUp } from "../../action_creators/auth_creators";
import TextField from "../form/TextField";
import * as Yup from "yup";

const USERNAME_VALIDATION_MESSAGE =
  "Usernames can only contain lowercase letters, numbers or the following characters . - _";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  username: Yup.string()
    .min(3, "Too Short!")
    .max(20, "Too Long!")
    .matches(/^[a-z0-9._-]{3,20}$/, {
      excludeEmptyString: true,
      message: USERNAME_VALIDATION_MESSAGE
    })
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").required("Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required")
});

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  alreadyAuthenticated() {
    const { push } = this.props;
    return push("/");
  }

  onSubmit({ email, password, username }) {
    const { signUp, showToast } = this.props;
    const formValues = { email, password, username };
    return signUp({
      formValues,
      onError: (errorString) => {
        return showToast({ message: errorString, type: "error" });
      }
    });
  }

  $formHeader() {
    return (
      <div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign Up</h2>
      </div>
    );
  }

  $form() {
    return (
      <div id="signup-form" className="max-w-md w-full space-y-8">
        {this.$formHeader()}
        <Formik
          onSubmit={this.onSubmit}
          validationSchema={DisplayingErrorMessagesSchema}
          initialValues={{
            password: "",
            passwordConfirmation: "",
            email: "",
            username: ""
          }}
        >
          {({ isSubmitting }) => (
            <Form className="mt-8 space-y-3">
              <TextField label="Email" name="email" placeholder="Email" autoComplete="email" />
              <TextField label="Username" name="username" placeholder="Username" autoComplete="username" />
              <TextField
                label="Password"
                name="password"
                placeholder="Password"
                type="password"
                autoComplete="new-password"
              />
              <TextField
                label="Password Confirmation"
                name="passwordConfirmation"
                placeholder="Password Confirmation"
                type="password"
                autoComplete="new-password"
              />
              <div className="buttons">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  Sign Up
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  render() {
    return (
      <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        {this.props.isAuthenticated ? this.alreadyAuthenticated() : this.$form()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
};

export default connect(mapStateToProps, { signUp, showToast, push })(Signup);
