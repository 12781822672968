import { SHOW_TOAST, CLEAR_TOAST } from "../action_types";

const SHOW_FOR_MS = 3000;

const show = ({ type, message }) => ({
  type: SHOW_TOAST,
  payload: { type, message }
});

const remove = () => ({
  type: CLEAR_TOAST
});

export const showToast = ({ type = "info", message }) => {
  return (dispatch) => {
    dispatch(show({ type, message }));

    setTimeout(() => {
      dispatch(remove());
    }, SHOW_FOR_MS);
  };
};
