import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./auth_reducer";
import detailReducer from "./detail_reducer";
import formValuesReducer from "./form_values_reducer";
import ingredientSearchReducer from "./ingredient_search_reducer";
import listsReducer from "./lists_reducer";
import recipeVersionReducer from "./recipe_version_reducer";
import selectOptionsReducer from "./select_options_reducer";
import toastReducer from "./toast_reducer";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    details: detailReducer,
    formValues: formValuesReducer,
    ingredientSearch: ingredientSearchReducer,
    lists: listsReducer,
    recipeVersion: recipeVersionReducer,
    selectOptions: selectOptionsReducer,
    toast: toastReducer
  });

export default appReducer;
