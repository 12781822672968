import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from "react-ga";
import "./index.css";
import Routes from "./routes";
import ScrollToTop from "./components/utils/ScrollToTop";
import reportWebVitals from "./reportWebVitals";
import configureStore, { history } from "./store";

Sentry.init({
  dsn: "https://1a391ed6a54f42e0910730deab49ec90@o817334.ingest.sentry.io/5807537",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV
});

ReactGA.initialize("UA-119936707-2");

export const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes />
          <ScrollToTop />
        </ConnectedRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
