import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import MobileHeaderDropdown from "./MobileHeaderDropdown";
import NavButton from "./NavButton";
import Subheader from "./Subheader";

import { signOut } from "../../../action_creators/auth_creators";

import cocktailLogo from "../../../images/libation_library_logo_v1.svg";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileDropdownIsOpen: false,
      settingsDropdownIsOpen: false
    };
    this.toggleMobileDropdown = this.toggleMobileDropdown.bind(this);
    this.toggleSettingsDropdown = this.toggleSettingsDropdown.bind(this);
    this.handleSignout = this.handleSignout.bind(this);
  }

  toggleMobileDropdown(_ev) {
    this.setState({ mobileDropdownIsOpen: !this.state.mobileDropdownIsOpen });
  }

  toggleSettingsDropdown(_ev) {
    this.setState({ settingsDropdownIsOpen: !this.state.settingsDropdownIsOpen });
  }

  handleSignout(_ev) {
    const { signOut } = this.props;
    this.setState({ settingsDropdownIsOpen: false, mobileDropdownIsOpen: false });

    return signOut();
  }

  $insideAppNavItems() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) return null;

    return (
      <div className="hidden md:block">
        <div className="ml-10 flex items-baseline space-x-4">
          <NavButton path="/inventory" text="Inventory" isMobile={false} />
          <NavButton path="/cocktails" text="Cocktails" isMobile={false} />
        </div>
      </div>
    );
  }

  $settingsDropdown() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) return null;

    const { settingsDropdownIsOpen } = this.state;
    const settingsDropdownClass = settingsDropdownIsOpen ? "" : "hidden";

    return (
      <div className="hidden md:block">
        <div className="ml-4 flex items-center md:ml-6">
          {/* <!-- Settings dropdown button --> */}
          <div className="ml-3 relative">
            <div>
              <button
                className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                id="settings-dropdown"
                aria-haspopup="true"
                onClick={this.toggleSettingsDropdown}
              >
                <span className="sr-only">Open user menu</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 text-gray-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {/* settings dropdown */}
            <div
              className={`${settingsDropdownClass} origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="settings-dropdown"
            >
              <button
                onClick={this.handleSignout}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 min-w-full"
                role="menuitem"
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  $outsideAppButtons() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) return null;

    return (
      <div className="ml-4 flex items-center md:ml-6 hidden md:block">
        <Link
          to="/login"
          className="inline-block px-6 py-1 mr-3 text-xs font-medium leading-6 text-center transition bg-purple-200 rounded-md shadow hover:shadow-lg hover:bg-purple-300"
        >
          Log In
        </Link>
        <Link
          to="/signup"
          className="inline-block px-6 py-1 text-xs font-medium leading-6 text-center text-white transition bg-blue-700 rounded-md shadow hover:shadow-lg hover:bg-blue-800"
        >
          Sign Up
        </Link>
      </div>
    );
  }

  render() {
    const { isAuthenticated, currentUser } = this.props;
    const { mobileDropdownIsOpen } = this.state;

    const logoLinkLocation = isAuthenticated ? "/inventory" : "/";

    return (
      <div id="header" className="sticky top-0 w-screen z-50">
        <div>
          <nav className="bg-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link to={logoLinkLocation} className="flex">
                      <img className="h-8 w-8" src={cocktailLogo} alt="Cocktail Logo" />
                      <h1 className="text-2xl font-bold text-negroni-red ml-3">Libation Library</h1>
                    </Link>
                  </div>
                  {this.$insideAppNavItems()}
                </div>
                {this.$settingsDropdown()}
                {this.$outsideAppButtons()}
                <div className="-mr-2 flex md:hidden">
                  {/* <!-- Mobile menu button --> */}
                  <button
                    onClick={this.toggleMobileDropdown}
                    className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">Open main menu</span>
                    {/* hamburger */}
                    <svg
                      className={`${mobileDropdownIsOpen ? "hidden" : "block"} h-6 w-6`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    {/* close icon */}
                    <svg
                      className={`${mobileDropdownIsOpen ? "block" : "hidden"} h-6 w-6`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            {/* mobile header */}
            <MobileHeaderDropdown
              mobileDropdownIsOpen={mobileDropdownIsOpen}
              isAuthenticated={isAuthenticated}
              currentUser={currentUser}
              handleSignout={this.handleSignout}
              toggleMobileDropdown={this.toggleMobileDropdown}
            />
          </nav>

          <Subheader isAuthenticated={isAuthenticated} />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    isAuthenticated: auth.isAuthenticated,
    currentUser: auth.currentUser
  };
}

export default connect(mapStateToProps, { signOut })(Header);
