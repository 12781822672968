import { GET_LIST_RECORDS_SUCCESS, LOAD_LIST, CLEAR_LIST } from "../action_types";

const DEFAULT_CONFIG = { data: null, error: false, loading: false };

const LIST_CONFIG = [
  {
    name: "recipeList"
  },
  {
    name: "userIngredientList"
  },
  {
    name: "recipeVersionsList"
  },
  {
    name: "genericItemList"
  }
];

const initialState = {};
LIST_CONFIG.forEach((config) => {
  initialState[config.name] = {
    ...DEFAULT_CONFIG
  };
});

const listReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_RECORDS_SUCCESS:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          ...action.payload.response,
          error: false,
          loading: false
        }
      };
    case LOAD_LIST:
      return {
        ...state,
        [action.payload.name]: { ...state[action.payload.name], error: false, loading: true }
      };
    case CLEAR_LIST:
      return {
        ...state,
        [action.payload.name]: {
          ...DEFAULT_CONFIG
        }
      };
    default:
      return state;
  }
};

export default listReducer;
