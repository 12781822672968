import { loadList, onGetListSuccess } from "./list_creators";
import api from "../utils/api";

export const LIST_NAME = "genericItemList";

export const getUnscopedIngredients = (params) => {
  return (dispatch) => {
    dispatch(loadList(LIST_NAME));

    return api.get("/ingredients", {
      config: { params },
      on: {
        success: (response) => {
          dispatch(onGetListSuccess({ response, listName: LIST_NAME }));
        }
      }
    });
  };
};
