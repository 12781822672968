export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS",
  AUTHENTICATION_SIGNOUT = "AUTHENTICATION_SIGNOUT",
  LOAD_INGREDIENT_SEARCH = "LOAD_INGREDIENT_SEARCH",
  GET_INGREDIENT_SEARCH_SUCCESS = "GET_INGREDIENT_SEARCH_SUCCESS",
  CLEAR_INGREDIENT_SEARCH = "CLEAR_INGREDIENT_SEARCH",
  GET_LIST_RECORDS_SUCCESS = "GET_LIST_RECORDS_SUCCESS",
  LOAD_LIST = "LOAD_LIST",
  CLEAR_LIST = "CLEAR_LIST",
  GET_DETAIL_SUCCESS = "GET_DETAIL_SUCCESS",
  LOAD_DETAIL = "LOAD_DETAIL",
  CLEAR_DETAIL = "CLEAR_DETAIL",
  SHOW_TOAST = "SHOW_TOAST",
  CLEAR_TOAST = "CLEAR_TOAST",
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
  GET_RECIPE_VERSION_SUCCESS = "GET_RECIPE_VERSION_SUCCESS",
  CLEAR_RECIPE_VERSION = "CLEAR_RECIPE_VERSION",
  LOAD_RECIPE_VERSION = "LOAD_RECIPE_VERSION",
  LOAD_SELECT_OPTION = "LOAD_SELECT_OPTION",
  GET_SELECT_OPTION_SUCCESS = "GET_SELECT_OPTION_SUCCESS",
  GET_FORM_VALUES_SUCCESS = "GET_FORM_VALUES_SUCCESS",
  LOAD_FORM_VALUES = "LOAD_FORM_VALUES",
  CLEAR_FORM_VALUES = "CLEAR_FORM_VALUES";
