import Cookies from "universal-cookie";
import { push } from "connected-react-router";
import api from "../utils/api";

import { AUTHENTICATION_SUCCESS, AUTHENTICATION_SIGNOUT } from "../action_types";

export const getAuthToken = () => {
  const cookies = new Cookies();
  return cookies.get("auth_token");
};

export const getCurrentUserFromCookie = () => {
  const cookies = new Cookies();
  return cookies.get("current_user");
};

const removeAuthToken = () => {
  const cookies = new Cookies();
  cookies.remove("auth_token", {
    path: "/"
  });
};

const removeCurrentUserCookie = () => {
  const cookies = new Cookies();
  cookies.remove("current_user", {
    path: "/"
  });
};

export const clearAuth = () => {
  removeAuthToken();
  removeCurrentUserCookie();
  return {
    type: AUTHENTICATION_SIGNOUT
  };
};

const setAuthToken = (token) => {
  const cookies = new Cookies();
  cookies.set("auth_token", token, {
    path: "/"
  });
};

const setCurrentUserCookie = (user) => {
  const cookies = new Cookies();
  cookies.set("current_user", user, {
    path: "/"
  });
};

const signInSuccess = (data) => {
  setAuthToken(data.token);
  setCurrentUserCookie(data.user);
  return {
    type: AUTHENTICATION_SUCCESS,
    payload: { data }
  };
};

export function signUp({ formValues, onError = () => {} }) {
  return function (dispatch) {
    return api.post("/sessions/sign_up", {
      config: { params: formValues },
      on: {
        success: (response) => {
          dispatch(signInSuccess(response.data));
          dispatch(push("/inventory"));
        },
        422: (response) => {
          const responseString = response.data.errors && response.data.errors.join(" ");
          return onError(responseString);
        }
      }
    });
  };
}

export function signIn({ formValues, onInvalid = () => {} }) {
  return function (dispatch) {
    return api.post("/sessions/sign_in", {
      config: { params: formValues },
      on: {
        success: (response) => {
          dispatch(signInSuccess(response.data));
          dispatch(push("/inventory"));
        },
        401: (response) => {
          return onInvalid(response.data);
        }
      }
    });
  };
}

export function signOut() {
  return function (dispatch) {
    return api.delete("/sessions/sign_out", {
      on: {
        success: (_response) => {
          dispatch(clearAuth());
          dispatch(push("/login"));
        }
      }
    });
  };
}
