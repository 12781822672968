import React from "react";
import NavButton from "./NavButton";

class MobileHeaderDropdown extends React.Component {
  $outsideAppNavItems() {
    const { isAuthenticated, toggleMobileDropdown } = this.props;
    if (isAuthenticated) return null;

    return (
      <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <NavButton path="/login" text="Login" isMobile onClick={toggleMobileDropdown} />
        <NavButton path="/signup" text="Sign Up" isMobile onClick={toggleMobileDropdown} />
      </div>
    );
  }

  $insideAppNavItems() {
    const { isAuthenticated, toggleMobileDropdown } = this.props;
    if (!isAuthenticated) return null;

    return (
      <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <NavButton path="/inventory" text="Inventory" isMobile onClick={toggleMobileDropdown} />
        <NavButton path="/cocktails" text="Cocktails" isMobile onClick={toggleMobileDropdown} />
      </div>
    );
  }

  $currentUserDetails() {
    const { currentUser } = this.props;
    if (!currentUser) return null;

    return (
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-gray-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <div className="text-base font-medium leading-none text-white">{currentUser.username}</div>
          <div className="text-sm font-medium leading-none text-gray-400">{currentUser.email}</div>
        </div>
      </div>
    );
  }

  $insideAppUserSettings() {
    const { isAuthenticated, handleSignout } = this.props;
    if (!isAuthenticated) return null;

    return (
      <div className="pt-4 pb-3 border-t border-gray-700">
        {this.$currentUserDetails()}
        <div className="mt-3 px-2 space-y-1">
          <button
            onClick={handleSignout}
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 min-w-full text-left"
          >
            Sign out
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { mobileDropdownIsOpen } = this.props;
    const showClass = mobileDropdownIsOpen ? "block" : "hidden";

    return (
      <div className={`${showClass} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {this.$outsideAppNavItems()}
          {this.$insideAppNavItems()}
        </div>
        {this.$insideAppUserSettings()}
      </div>
    );
  }
}

export default MobileHeaderDropdown;
