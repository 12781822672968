import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { push } from "connected-react-router";
import { signIn } from "../../action_creators/auth_creators";
import { showToast } from "../../action_creators/toast_creators";
import TextField from "../form/TextField";
import * as Yup from "yup";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required")
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  alreadyAuthenticated() {
    const { push } = this.props;
    return push("/inventory");
  }

  onSubmit({ email, password }) {
    const { signIn, showToast } = this.props;
    const formValues = { email, password };
    return signIn({
      formValues,
      onInvalid: (errorString) => {
        return showToast({ message: errorString, type: "error" });
      }
    });
  }

  $formHeader() {
    return (
      <div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      </div>
    );
  }

  $form() {
    return (
      <div id="login-form" className="max-w-md w-full space-y-8">
        {this.$formHeader()}
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{
            password: "",
            email: ""
          }}
          validationSchema={DisplayingErrorMessagesSchema}
        >
          {({ isSubmitting }) => (
            <Form className="mt-8 space-y-3">
              <TextField label="Email" name="email" placeholder="Email" autoComplete="email" />
              <TextField
                label="Password"
                name="password"
                placeholder="Password"
                type="password"
                autoComplete="current-password"
              />
              <div className="buttons">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  Log In
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  render() {
    return (
      <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        {this.props.isAuthenticated ? this.alreadyAuthenticated() : this.$form()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
};

export default connect(mapStateToProps, { signIn, showToast, push })(Login);
