import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUnscopedIngredients } from "../../../../../action_creators/unscoped_ingredient_creators";
import { groupDataByCategory } from "./add_recipe_item_utils";
import { IngredientGroup } from "./IngredientGroup";

function GenericItemList({
  title,
  ingredientType,
  getUnscopedIngredients,
  data,
  loading,
  onItemClick,
  isGenericSpirit
}) {
  useEffect(() => {
    const params = isGenericSpirit ? { isGeneric: true } : { ingredientType };
    getUnscopedIngredients(params);
  }, [getUnscopedIngredients, ingredientType, isGenericSpirit]);

  const $loading = () => (
    <div className="animate-pulse mt-4">
      <div className="h-4 mt-2 bg-gray-300 rounded w-full"></div>
      <div className="h-4 mt-2 bg-gray-300 rounded w-full"></div>
      <div className="h-4 mt-2 bg-gray-300 rounded w-full"></div>
      <div className="h-4 mt-2 bg-gray-300 rounded w-full"></div>
    </div>
  );

  const $grouped = () => {
    const groupedData = groupDataByCategory(data, false);
    const sortedKeys = Object.keys(groupedData).sort();

    return sortedKeys.map((groupName) => {
      return (
        <IngredientGroup
          key={groupName}
          onItemClick={onItemClick}
          groupName={groupName}
          categoryData={groupedData[groupName]}
        />
      );
    });
  };

  function $items() {
    if (loading || !data) return $loading();
    if (isGenericSpirit) return $grouped();

    return (
      <div className="divide-y divide-light-blue-400 mt-4 border">
        <div className="p-2 py-3 bg-gray-100 w-full">
          <h3 className="font-semibold text-blue-700">{title}</h3>
        </div>

        {data.map((item) => (
          <button
            key={item.id}
            onClick={() => onItemClick({ ingredientId: item.id, name: item.name })}
            className="generic-ingredient-item p-2 py-3 hover:bg-blue-50 w-full text-left"
          >
            {item.name}
          </button>
        ))}
      </div>
    );
  }

  return (
    <div id="generic-item-list" className="">
      {$items()}
    </div>
  );
}

const mapStateToProps = ({ lists: { genericItemList } }) => ({
  data: genericItemList.data,
  loading: genericItemList.loading
});

export default connect(mapStateToProps, { getUnscopedIngredients })(GenericItemList);
