import React from "react";
import { connect } from "react-redux";
import { searchIngredient } from "../../action_creators/ingredient_search_creators";
import { saveUserIngredient } from "../../action_creators/user_ingredient_creators";
import { showToast } from "../../action_creators/toast_creators";
import IngredientSearchResults from "./IngredientSearchResults";

class IngredientSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      hasSearch: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResultClick = this.onResultClick.bind(this);
  }

  handleInputChange(ev) {
    this.setState({ searchTerm: ev.target.value });
  }

  onSubmit(ev) {
    ev.preventDefault();
    const { searchTerm } = this.state;
    const { searchIngredient } = this.props;
    if (!searchTerm) return false;
    this.setState({ hasSearched: true });

    searchIngredient(searchTerm);
  }

  onResultClick(attrs) {
    const { searchTerm } = this.state;
    const { searchIngredient, saveUserIngredient, showToast, onAddIngredientSuccess } = this.props;
    if (attrs.userIngredient) return showToast({ message: "Item already saved!" });

    return saveUserIngredient({
      ingredientId: attrs.id,
      onSuccess: (_response) => {
        showToast({ message: "Ingredient Saved!" });
        if (onAddIngredientSuccess) onAddIngredientSuccess();
        return searchIngredient(searchTerm);
      }
    });
  }

  render() {
    const { loading, data } = this.props;
    const { hasSearched } = this.state;

    return (
      <div id="ingredient-search" className="container mx-auto max-w-lg px-4 mt-8">
        <form onSubmit={this.onSubmit}>
          <label className="block mb-2 font-semibold" htmlFor="searchTerm">
            <span className="text-gray-700">Ingredient Search</span>
          </label>
          <div className="relative">
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder=""
              name="searchTerm"
              onChange={this.handleInputChange}
            />
            <button
              type="submit"
              className="absolute inset-y-0 right-0 flex items-center px-4 font-bold text-white bg-indigo-600 rounded-r-lg hover:bg-indigo-500 focus:bg-indigo-700"
            >
              Search
            </button>
          </div>
        </form>
        <IngredientSearchResults
          results={data}
          loading={loading}
          hasSearched={hasSearched}
          onResultClick={this.onResultClick}
        />
      </div>
    );
  }
}

function mapStateToProps({ ingredientSearch: { data, loading } }) {
  return {
    data,
    loading
  };
}

export default connect(mapStateToProps, { searchIngredient, saveUserIngredient, showToast })(IngredientSearch);
