import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { listRecipes, LIST_NAME } from "../../../action_creators/recipe_creators";
import { clearList } from "../../../action_creators/list_creators";
import RecipeCardLoading from "./RecipeCardLoading";
import RecipeCard from "./RecipeCard";

class Cocktails extends React.Component {
  componentDidMount() {
    const { listRecipes } = this.props;
    return listRecipes();
  }

  componentWillUnmount() {
    const { clearList } = this.props;
    return clearList(LIST_NAME);
  }

  $recipeCards() {
    const {
      recipeList: { data, loading }
    } = this.props;
    if (loading || !data) return <RecipeCardLoading />;
    if (!data.length) return this.$emptyState();

    return (
      <div className="recipe-cards flex flex-wrap mt-3">
        {data.map((recipe) => (
          <RecipeCard key={`recipe-card-${recipe.id}`} recipe={recipe} />
        ))}
      </div>
    );
  }

  $subheader() {
    return (
      <div className="page-subheader mb-3 flex justify-between">
        <h3 className="text-xl font-bold text-gray-700">My Cocktail Recipes</h3>
        <Link to="/cocktails/new" className="btn-blue-primary">
          Add New
        </Link>
      </div>
    );
  }

  $inventoryLink() {
    return (
      <Link to="/inventory" className="text-blue-700 hover:underline">
        bar inventory
      </Link>
    );
  }

  $emptyState() {
    return (
      <div className="mt-8 p-6 text-base bg-white text-center border rounded shadow-md">
        <h3 className="font-semibold text-lg text-blue-700">Welcome Friend</h3>
        <div className="max-w-lg mx-auto mb-3">
          Get started by first adding spirits to your {this.$inventoryLink()}. Then add cocktail recipes here. Once
          created, you can track multiple versions within the same recipe.
        </div>
        <Link to="/cocktails/new" className="btn-blue-primary">
          Add New
        </Link>
      </div>
    );
  }

  render() {
    return (
      <div id="cocktails" className="min-h-screen mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          {this.$subheader()}
          {this.$recipeCards()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ lists: { recipeList } }) => {
  return {
    recipeList
  };
};

export default connect(mapStateToProps, { listRecipes, clearList })(Cocktails);
