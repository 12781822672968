import { Link } from "react-router-dom";
import RecipeVersionItem from "../recipes/recipe_version/RecipeVersionItem";

const RecipeCard = ({ recipe: { title, id, recipeVersions } }) => {
  const $noFavorite = () => (
    <div className="border bg-blue-50 relative mt-4 p-3 rounded">No Favorite Recipe Added Yet!</div>
  );

  const $favoriteRecipe = () => {
    if (!recipeVersions.data || !recipeVersions.data.length) return $noFavorite();
    const favoriteRecipe = recipeVersions.data.find((version) => version.isFavorite);
    if (!favoriteRecipe) return $noFavorite();

    return <RecipeVersionItem recipeVersion={favoriteRecipe} />;
  };

  return (
    <Link
      to={`cocktails/${id}`}
      className="recipe-card w-full flex-grow md:w-5/12 max-w-md border shadow hover:shadow-lg rounded-md bg-white p-6 sm:mr-3 mt-3"
    >
      <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
      {$favoriteRecipe()}
    </Link>
  );
};

export default RecipeCard;
