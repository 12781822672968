import { GET_SELECT_OPTION_SUCCESS, LOAD_SELECT_OPTION } from "../action_types";

const DEFAULT_CONFIG = { values: null, error: false, loading: false };

const LIST_CONFIG = [
  {
    name: "userIngredients"
  }
];

const initialState = {};
LIST_CONFIG.forEach((config) => {
  initialState[config.name] = {
    ...DEFAULT_CONFIG
  };
});

const listReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELECT_OPTION_SUCCESS:
      return {
        ...state,
        [action.payload.name]: {
          values: action.payload.values,
          error: false,
          loading: false
        }
      };
    case LOAD_SELECT_OPTION:
      return {
        ...state,
        [action.payload.name]: { ...state[action.payload.name], error: false, loading: true }
      };
    default:
      return state;
  }
};

export default listReducer;
