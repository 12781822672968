import { LOAD_INGREDIENT_SEARCH, GET_INGREDIENT_SEARCH_SUCCESS, CLEAR_INGREDIENT_SEARCH } from "../action_types";
import api from "../utils/api";

function getIngredientSearchSuccess(data) {
  return {
    type: GET_INGREDIENT_SEARCH_SUCCESS,
    payload: { data }
  };
}

function loadIngredientSearch() {
  return {
    type: LOAD_INGREDIENT_SEARCH
  };
}

export function clearIngredientSearch() {
  return {
    type: CLEAR_INGREDIENT_SEARCH
  };
}

export function searchIngredient(searchTerm) {
  return function (dispatch) {
    dispatch(loadIngredientSearch());

    return api.get("/ingredients/search", {
      config: { params: { searchTerm } },
      on: {
        success: (response) => {
          dispatch(getIngredientSearchSuccess(response.data));
        }
      }
    });
  };
}
