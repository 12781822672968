import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class PrivateRoute extends React.Component {
  render() {
    const { isAuthenticated, location, Component } = this.props;
    console.log(isAuthenticated);
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location }
          }}
        />
      );
    }
    return <Route component={Component} {...this.props} />;
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    isAuthenticated: auth.isAuthenticated
  };
};

export default connect(mapStateToProps)(PrivateRoute);
