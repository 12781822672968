import React, { useState } from "react";
import { FieldArray, Field } from "formik";
import classnames from "classnames";
import AddRecipeItem from "./add_recipe_item/AddRecipeItem";
import IngredientFormItem from "./IngredientFormItem";

const RecipeVersionForm = ({ recipe, formikValues, newRecipe, errors, submitCount }) => {
  const [showAddItemOverlay, setShowAddItemOverlay] = useState(false);

  const toggleAddItemOverlay = () => setShowAddItemOverlay(!showAddItemOverlay);

  const $fieldArrayNullState = (showError) => (
    <div
      className={classnames("mt-2 mb-1 p-4 text-sm sm:text-base bg-white text-center border rounded", {
        "border-red-500": showError
      })}
    >
      <h3>Add at least one ingredient to save this recipe version</h3>
      <button type="button" className="btn-secondary mt-3 w-full md:w-auto" onClick={toggleAddItemOverlay}>
        Add Ingredient +
      </button>
    </div>
  );

  const $fieldArrayItems = ({ values, arrayHelpers, showError }) => {
    if (!values.recipeIngredients || !values.recipeIngredients.length) return $fieldArrayNullState(showError);

    return (
      <div className={`mb-3 mt-2 p-2 lg:p-4 rounded ${showAddItemOverlay ? "bg-white border shadow" : "bg-gray-50"}`}>
        {values.recipeIngredients.map((ingredient, index) => (
          <IngredientFormItem
            key={ingredient.ingredientId}
            recipeIngredient={ingredient}
            arrayHelpers={arrayHelpers}
            fieldArrayIndex={index}
          />
        ))}
        <button type="button" className="btn-secondary mt-3 w-full md:w-auto" onClick={toggleAddItemOverlay}>
          Add Ingredient +
        </button>
      </div>
    );
  };

  const $fieldArray = (values) => {
    const optionalShowClass = showAddItemOverlay ? "hidden" : "";
    const showError = errors.recipeIngredients && submitCount > 0;

    return (
      <FieldArray
        name="recipeIngredients"
        render={(arrayHelpers) => (
          <div id="recipe-ingredients" className="mt-4">
            <h4 className="font-medium">Ingredients</h4>

            {showAddItemOverlay && (
              <AddRecipeItem fieldArrayHelpers={arrayHelpers} toggleAddItemOverlay={toggleAddItemOverlay} />
            )}

            <div id="recipe-ingredients-optional-show" className={optionalShowClass}>
              {$fieldArrayItems({ values, arrayHelpers, showError })}
              {showError && <span className="text-red-700 text-sm">{errors.recipeIngredients}</span>}
            </div>
          </div>
        )}
      />
    );
  };

  const $markAsFavorite = () => {
    if (newRecipe) return null;

    return (
      <label className="block cursor-pointer p-3 mt-3 border rounded hover:border-yellow-300 hover:bg-yellow-50">
        <Field
          type="checkbox"
          name="isFavorite"
          className="focus:outline-none h-4 w-4 text-yellow-400 border-gray-300 rounded"
        />
        <span className="ml-3">Mark as Favorite</span>
      </label>
    );
  };

  const paddingClasses = newRecipe ? "" : "px-4 pt-5 pb-4 sm:p-6 sm:pb-4";

  return (
    <div className={`bg-white ${paddingClasses}`}>
      {recipe && <h3 className="font-semibold mb-2 text-lg text-gray-700">{recipe.title} Recipe Version</h3>}

      {$fieldArray(formikValues)}
      {$markAsFavorite()}
      <label htmlFor="notes" className="block mt-4">
        Notes
      </label>
      <Field
        name="notes"
        placeholder="Notes"
        component="textarea"
        className="mt-2 mb-1 text-base relative w-full border rounded placeholder-gray-400 focus:border-indigo-400 focus:outline-none"
      />
    </div>
  );
};

export default RecipeVersionForm;
