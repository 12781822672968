import IngredientImage from "./IngredientImage";

const $optionalItem = ({ text, title }) => {
  if (!text) return null;
  return (
    <div className="text-gray-700">
      <span className="font-semibold">{title}:</span> {text}
    </div>
  );
};

const $img = ({ publicImage, name, category, subCategory }) => {
  return (
    <div className="flex-none flex w-32 h-40 relative p-4">
      <IngredientImage publicImage={publicImage} itemName={name} category={category} subCategory={subCategory} />
    </div>
  );
};

const $forSearchItem = (itemInInventory) => {
  if (!itemInInventory) return null;
  return <span className="rounded px-2 py-1 ml-2 bg-blue-200 text-blue-800 font-medium flex-grow-0">Saved</span>;
};

const extraClasses = ({ forSearch, itemInInventory }) => {
  if (!forSearch) {
    return "border-gray-200";
  } else if (itemInInventory) {
    return "border-blue-200";
  } else {
    return "border-gray-200 hover:shadow-lg cursor-pointer";
  }
};

const IngredientItem = ({ item, forSearch }) => {
  const { id, name, brand, category, subCategory, publicImage, style, userIngredient } = item;
  const itemInInventory = !!userIngredient;
  const classes = extraClasses({ forSearch, itemInInventory });

  return (
    <div key={id} className={`ingredient-result flex my-3 rounded-lg border shadow break-words ${classes}`}>
      {$img({ publicImage, name, category, subCategory })}
      <div className="min-w-0 relative flex-auto p-4 bg-gray-100 rounded-tr-lg rounded-br-lg">
        <div className="flex justify-between items-start">
          <h2 className="text-lg font-semibold text-black mb-0.5">{name}</h2>
          {forSearch && $forSearchItem(itemInInventory)}
        </div>
        <div className="font-medium">
          {$optionalItem({ text: brand, title: "Brand" })}
          {$optionalItem({ text: category, title: "Category" })}
          {$optionalItem({ text: subCategory, title: "Sub Category" })}
          {$optionalItem({ text: style, title: "Style" })}
        </div>
      </div>
    </div>
  );
};

export default IngredientItem;
