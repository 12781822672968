import alcoholPlaceholderImg from "../../../images/alcohol-placeholder.svg";
import citrusImg from "../../../images/lime.svg";

const getImage = ({ category, subCategory, publicImage }) => {
  if (publicImage) return publicImage;

  switch (category) {
    case "Juice":
      return citrusImg;

    default:
      return alcoholPlaceholderImg;
  }
};

const IngredientImage = ({ publicImage, itemName, category, subCategory }) => {
  return (
    <img src={getImage({ category, subCategory, publicImage })} alt={itemName} className="max-w-full max-h-full" />
  );
};

export default IngredientImage;
