import axios, { CancelToken } from "axios";
import * as Sentry from "@sentry/react";
import config from "../config";
import snakeCaseKeys from "./snakecase_keys";
import camelcaseKeys from "./camelcase_keys";
import { getAuthToken, clearAuth } from "../action_creators/auth_creators";
import { store } from "../index";
import { showToast } from "../action_creators/toast_creators";

const url = (path) => {
  return config.apiHost + (path[0] === "/" ? path : path.slice(-(path.length - 1)));
};

const camelizeOutput = (rawResponse) => {
  const response = Object.assign({}, rawResponse);
  return (response.data = response.data !== "" ? camelcaseKeys(response.data, { deep: true }) : response.data);
};

// Axios overrides
let cancelToken, cancelSource;
function generateCancelSource() {
  cancelSource = CancelToken.source();
  cancelToken = cancelSource.token;
}
generateCancelSource();

axios.interceptors.request.use(
  function (config) {
    const authToken = getAuthToken();
    if (authToken) config.headers.authorization = `Bearer ${authToken}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const handle401 = () => {
  cancelSource.cancel();
  generateCancelSource();
  store.dispatch(clearAuth());
};

const handleError = (error) => {
  const errorMessage = error.response?.data?.errors?.join(", ") ?? "Something went wrong, we've been notified";
  store.dispatch(showToast({ message: errorMessage, type: "error" }));
  // Manually send exception to sentry that might get swallowed by Formik
  Sentry.captureException(error, {
    contexts: {
      context: {
        message: errorMessage
      },
      errorJson: error.toJSON(),
      response: error?.response?.data
    }
  });
};

function request(method, path, { camelOutput, config, on } = {}) {
  const opts = {
    method,
    url: url(path),
    cancelToken,
    ...config
  };

  return axios(opts)
    .then((response) => {
      const onStatus = on[response.status.toString()] || on.success || function () {};

      return onStatus(camelOutput ? camelizeOutput(response) : response);
    })
    .catch((error) => {
      const status = error.response.status.toString();
      if (!!on[status]) return on[status](error.response);
      if (status === "401") return handle401();
      // if (status == "403") return (on[status] || handle403)(error.response);
      if (!!on.error) return on.error(error.response);
      // if (!!ON_ERROR_DEFAULTS[status]) return ON_ERROR_DEFAULTS[status](error.response);

      return handleError(error);
    });
}

const api = {
  get: function (path, { snakeInput = true, camelOutput = true, config = {}, on = {} } = {}) {
    const conf = snakeInput ? snakeCaseKeys(config) : config;
    return request("get", path, { camelOutput, config: conf, on });
  },

  post: function (path, { snakeInput = true, camelOutput = true, config = {}, on = {} } = {}) {
    const conf = snakeInput ? snakeCaseKeys(config) : config;
    return request("post", path, { camelOutput, config: conf, on });
  },

  put: function (path, { snakeInput = true, camelOutput = true, config = {}, on = {} } = {}) {
    const conf = snakeInput ? snakeCaseKeys(config) : config;
    return request("put", path, { camelOutput, config: conf, on });
  },

  delete: function (path, { camelOutput = true, config = {}, on = {} } = {}) {
    return request("delete", path, { camelOutput, config, on });
  }
};

export default api;
