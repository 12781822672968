import { LOAD_LIST, GET_LIST_RECORDS_SUCCESS, CLEAR_LIST } from "../action_types";

export const clearList = (listName) => ({
  type: CLEAR_LIST,
  payload: { name: listName }
});

export const loadList = (listName) => ({
  type: LOAD_LIST,
  payload: { name: listName }
});

export const onGetListSuccess = ({ response, listName }) => ({
  type: GET_LIST_RECORDS_SUCCESS,
  payload: { name: listName, response }
});
