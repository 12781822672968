import React from "react";
import { connect } from "react-redux";
import { listUserIngredients, LIST_NAME } from "../../../action_creators/user_ingredient_creators";
import { clearList } from "../../../action_creators/list_creators";
import IngredientItem from "./IngredientItem";
import LargeModal from "../../modal/LargeModal";
import IngredientSearch from "../../ingredient_search/IngredientSearch";

class UserIngredients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onAddIngredientSuccess = this.onAddIngredientSuccess.bind(this);
  }

  componentDidMount() {
    const { listUserIngredients } = this.props;
    return listUserIngredients();
  }

  componentWillUnmount() {
    const { clearList } = this.props;
    return clearList(LIST_NAME);
  }

  toggleModal(_ev) {
    return this.setState({ showAddModal: !this.state.showAddModal });
  }

  onAddIngredientSuccess() {
    const { listUserIngredients } = this.props;
    this.setState({ showAddModal: false });
    return listUserIngredients();
  }

  groupDataByCategory(data) {
    return data.reduce(
      (result, item) => ({
        ...result,
        [item.ingredient.category]: [...(result[item.ingredient.category] || []), item]
      }),
      {}
    );
  }

  $emptyState() {
    return (
      <div className="mt-8 p-6 text-base bg-white text-center border rounded shadow-md">
        <h3 className="font-semibold text-lg text-blue-700">Welcome Friend</h3>
        <div className="max-w-lg mx-auto">
          Get started by adding spirits to your bar inventory. You can then pull from your inventory to save cocktail
          recipes.
        </div>
        <button onClick={this.toggleModal} className="btn-blue-primary mt-3">
          Add To My Inventory
        </button>
      </div>
    );
  }

  $categoryGroups({ key, categoryData }) {
    const sortedCategoryData = categoryData.sort((a, b) => {
      if (a.ingredient.name < b.ingredient.name) return -1;
      if (a.ingredient.name > b.ingredient.name) return 1;
      return 0;
    });

    return (
      <div className="ingredient-category-group mt-2" key={key}>
        <h4 className="text-lg font-bold text-blue-600">{key}</h4>
        <div className="flex flex-wrap">
          {sortedCategoryData.map((item) => (
            <div key={item.id} className="ingredient-wrapper mr-4 sm:w-96 w-full">
              <IngredientItem item={item.ingredient} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  $ingredients() {
    const {
      userIngredients: { data, loading }
    } = this.props;
    if (!data || loading) return null;
    if (!data.length) return this.$emptyState();

    const groupedData = this.groupDataByCategory(data);
    const sortedKeys = Object.keys(groupedData).sort();

    const $groupedIngredients = sortedKeys.map((key) => {
      return this.$categoryGroups({ key, categoryData: groupedData[key] });
    });

    return $groupedIngredients;
  }

  $subheader() {
    return (
      <div className="page-subheader mb-3 flex justify-between">
        <h3 className="text-xl font-bold text-blue-800">My Inventory</h3>
        <button onClick={this.toggleModal} className="btn-blue-primary">
          Add New
        </button>
      </div>
    );
  }

  $addModal() {
    const { showAddModal } = this.state;
    if (!showAddModal) return null;

    return (
      <LargeModal modalClose={this.toggleModal}>
        <div className="pt-4 pb-12" id="ingredient-search-modal">
          <IngredientSearch onAddIngredientSuccess={this.onAddIngredientSuccess} />
        </div>
      </LargeModal>
    );
  }

  render() {
    return (
      <div id="user-ingredients" className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        {this.$addModal()}
        {this.$subheader()}
        {this.$ingredients()}
      </div>
    );
  }
}

const mapStateToProps = ({ lists: { [LIST_NAME]: userIngredients } }) => {
  return {
    userIngredients
  };
};

export default connect(mapStateToProps, { listUserIngredients, clearList })(UserIngredients);
