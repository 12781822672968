import { LOAD_INGREDIENT_SEARCH, GET_INGREDIENT_SEARCH_SUCCESS, CLEAR_INGREDIENT_SEARCH } from "../action_types";

const INITIAL_STATE = { data: null, loading: false };

const ingredientSearchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INGREDIENT_SEARCH_SUCCESS:
      return { data: action.payload.data, loading: false };
    case LOAD_INGREDIENT_SEARCH:
      return { data: null, loading: true };
    case CLEAR_INGREDIENT_SEARCH:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default ingredientSearchReducer;
