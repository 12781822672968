import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";

import PrivateRoute from "./components/utils/PrivateRoute";

import AppWrapper from "./components/utils/AppWrapper";

// ****** Public Pages ******
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import SplashPage from "./components/pages/SplashPage";

// ****** Private Pages ******
import UserIngredients from "./components/pages/ingredients/UserIngredients";
import IngredientSearch from "./components/ingredient_search/IngredientSearch";
import Cocktails from "./components/pages/recipes/Cocktails";
import RecipeDetail from "./components/pages/recipes/RecipeDetail";
import RecipeEdit from "./components/pages/recipes/RecipeEdit";
import NewRecipe from "./components/pages/recipes/NewRecipe";

function Routes() {
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <AppWrapper>
      <Switch>
        <Route exact path="/" component={SplashPage} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <PrivateRoute exact path="/inventory" component={UserIngredients} />
        <PrivateRoute path="/ingredients/search" component={IngredientSearch} />
        <PrivateRoute exact path="/cocktails" component={Cocktails} />
        <PrivateRoute exact path="/cocktails/new" component={NewRecipe} />
        <PrivateRoute exact path="/cocktails/:id/edit" component={RecipeEdit} />
        <PrivateRoute path="/cocktails/:id" component={RecipeDetail} />
      </Switch>
    </AppWrapper>
  );
}

export default Routes;
