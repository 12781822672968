import IngredientItemLoading from "./IngredientItemLoading";
import IngredientItem from "../pages/ingredients/IngredientItem";

const $noResults = () => (
  <div className="border shadow rounded-md p-8 my-3 w-full mx-auto">
    Ah Shucks, it looks like we don't have this item
  </div>
);

const IngredientSearchResults = ({ results, loading, hasSearched, onResultClick }) => {
  if (!hasSearched) return null;
  if (loading) return <IngredientItemLoading />;
  if (!results || !results.length) return $noResults();

  return (
    <div id="ingredient-search-results">
      {results.map((result) => (
        <div
          key={`search-result-${result.id}`}
          onClick={() => onResultClick(result)}
          className="ingredient-search-result-wrapper"
        >
          <IngredientItem item={result} forSearch />
        </div>
      ))}
    </div>
  );
};

export default IngredientSearchResults;
