// https://github.com/sindresorhus/camelcase-keys
// pulled into src to avoid polyfilling any node_modules

import camelcase from "./camelcase";
import QuickLru from "./quick_lru";
import mapObj from "./map_obj";

const has = (arr, key) => arr.some((x) => (typeof x === "string" ? x === key : x.test(key)));
const cache = new QuickLru({ maxSize: 100000 });

const camelcaseConvert = (input, opts) => {
  opts = Object.assign(
    {
      deep: false
    },
    opts
  );

  const exclude = opts.exclude;

  return mapObj(
    input,
    (key, val) => {
      if (!(exclude && has(exclude, key))) {
        if (cache.has(key)) {
          key = cache.get(key);
        } else {
          const ret = camelcase(key);

          if (key.length < 100) {
            // Prevent abuse
            cache.set(key, ret);
          }

          key = ret;
        }
      }

      return [key, val];
    },
    { deep: opts.deep }
  );
};

const camelcaseKeys = (input, opts) => {
  if (Array.isArray(input)) {
    return Object.keys(input).map((key) => camelcaseConvert(input[key], opts));
  }
  return camelcaseConvert(input, opts);
};

export default camelcaseKeys;
