import "./modal.css";

const LargeModal = (props) => {
  return (
    <div className="modal">
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="block justify-center min-h-screen pt-2 px-2 pb-20 text-center p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-4 sm:my-8 sm:align-middle max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {props.modalClose && (
              <div
                onClick={props.modalClose}
                className="absolute z-50 shadow bg-gray-100 top-0 right-0 p-2 rounded-bl cursor-pointer text-gray-600 hover:text-gray-800 flex"
              >
                <button className="w-6 h-6 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            )}
            <div className="modal-content">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeModal;
