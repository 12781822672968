import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import TextField from "../../form/TextField";
import { createRecipe } from "../../../action_creators/recipe_creators";
import RecipeVersionForm from "./recipe_version/RecipeVersionForm";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  title: Yup.string().required("Please add a recipe name"),
  recipeIngredients: Yup.array().min(1).required("Please add at least 1 ingredient")
});

const NewRecipe = ({ createRecipe }) => {
  const onSubmit = (formValues) => createRecipe({ formValues });

  return (
    <div id="recipe-detail" className="max-w-7xl mx-auto pb-12 pt-6 px-4 sm:px-6 lg:px-8">
      <div className="border shadow lg:p-6 p-3">
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            title: "",
            notes: "",
            isFavorite: true
          }}
          validationSchema={DisplayingErrorMessagesSchema}
        >
          {({ isSubmitting, values, errors, submitCount }) => (
            <Form className="mt-3 space-y-3">
              <h3 className="text-xl">New Cocktail Recipe</h3>
              <TextField label="Recipe Name" name="title" placeholder="Recipe Name" />
              <RecipeVersionForm formikValues={values} errors={errors} submitCount={submitCount} newRecipe />
              <div className="flex justify-end sticky bottom-0 bg-white py-2">
                <Link to="/cocktails" className="btn-secondary">
                  Cancel
                </Link>
                <button type="submit" disabled={isSubmitting} className="btn-blue-primary ml-2">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (_state) => {
  return {};
};

export default connect(mapStateToProps, { createRecipe })(NewRecipe);
