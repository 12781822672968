import React, { useState } from "react";
import MeasurementInputs from "./MeasurementInputs";
import { decimalToFraction, formatUnitForDisplay } from "../../../../utils/measurement_display";

const IngredientFormItem = ({ recipeIngredient, arrayHelpers, fieldArrayIndex }) => {
  const isInitialAdd = !!recipeIngredient.initialAdd;
  const [isEditable, setIsEditable] = useState(isInitialAdd);

  const $fieldArrayDeleteBtn = ({ arrayHelpers, fieldArrayIndex }) => {
    return (
      <div className="button-container">
        <button
          type="button"
          className="inline-block mt-2 p-3 text-center text-red-500 hover:text-white transition rounded-md hover:shadow-lg hover:bg-red-600 focus:outline-none"
          onClick={() => arrayHelpers.remove(fieldArrayIndex)}
        >
          <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    );
  };

  const $openEditButton = () => {
    return (
      <div className="button-container">
        <button
          type="button"
          className="inline-block p-3 text-center text-blue-500 hover:text-white transition rounded-md hover:shadow-lg hover:bg-blue-600 focus:outline-none"
          onClick={() => setIsEditable(true)}
          alt="edit"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };

  const containerClasses = "sm:mt-2 mb-1 text-sm sm:text-base bg-white border rounded";

  if (!isEditable) {
    return (
      <div className={`${containerClasses} flex justify-between align-middle`}>
        <div className="p-2 pt-3 font-medium">
          <span className="text-blue-700">
            {decimalToFraction(recipeIngredient.measurement)} {formatUnitForDisplay(recipeIngredient.measurementType)}{" "}
          </span>
          <span className="text-grey-700">{recipeIngredient.name}</span>
        </div>
        {$openEditButton()}
      </div>
    );
  }

  return (
    <div className={`${containerClasses} py-2 pl-2`}>
      <span className="text-grey-700 font-medium">{recipeIngredient.name}</span>
      <div className="flex justify-between flex-wrap">
        <MeasurementInputs fieldArrayIndex={fieldArrayIndex} value={recipeIngredient} />
        {$fieldArrayDeleteBtn({ arrayHelpers, fieldArrayIndex })}
      </div>
    </div>
  );
};

export default IngredientFormItem;
