import React from "react";
import Header from "./header/Header";
import ToastNotification from "./ToastNotification";

class AppWrapper extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div id="app-wrapper" className="min-h-screen">
        <Header />
        <ToastNotification />
        <main id="page-body" className="">
          {children}
        </main>
      </div>
    );
  }
}

export default AppWrapper;
