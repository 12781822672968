import { useLocation } from "react-router-dom";

const GetText = () => {
  let { pathname } = useLocation();

  switch (pathname) {
    case "/ingredients/search":
    case "/inventory":
      return "Inventory";
    case "/cocktails":
      return "Cocktails";
    default:
      return "Cocktails";
  }
};

const SubHeader = ({ isAuthenticated }) => {
  if (!isAuthenticated) return null;

  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-bold leading-tight text-gray-900">{GetText()}</h1>
      </div>
    </header>
  );
};

export default SubHeader;
