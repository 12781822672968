import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";

const DEFAULT_OPTIONS = [
  { value: 0.125, label: "⅛" },
  { value: 0.25, label: "¼" },
  { value: 0.5, label: "½" },
  { value: 0.75, label: "¾" },
  { value: 1, label: "1" },
  { value: 1.25, label: "1 ¼" },
  { value: 1.5, label: "1 ½" },
  { value: 1.75, label: "1 ¾" },
  { value: 2, label: "2" },
  { value: 2.25, label: "2 ¼" },
  { value: 2.5, label: "2 ½" },
  { value: 2.75, label: "2 ¾" },
  { value: 3, label: "3" }
];

const SIMPLE_OPTIONS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" }
];

const SELECT_OPTIONS = {
  oz: DEFAULT_OPTIONS,
  part: DEFAULT_OPTIONS,
  none: DEFAULT_OPTIONS,
  dash: SIMPLE_OPTIONS,
  barspoon: SIMPLE_OPTIONS
};

const MeasurementInputs = ({ fieldArrayIndex, value: { measurementType, measurement } }) => {
  const measurementOptions = SELECT_OPTIONS[measurementType];
  const { touched, setFieldValue } = useFormikContext();

  useEffect(() => {
    const validOption = measurementOptions.find((option) => option.value === parseFloat(measurement));
    if (!validOption) {
      setFieldValue(`recipeIngredients[${fieldArrayIndex}].measurement`, "1");
    }
  }, [measurementType, touched.measurementType, setFieldValue, fieldArrayIndex, measurement, measurementOptions]);

  return (
    <div className="recipe-version-form--input-container">
      <Field
        name={`recipeIngredients[${fieldArrayIndex}].measurement`}
        component="select"
        className="mt-2 mb-1 w-24 text-base relative border rounded focus:border-indigo-400 focus:outline-none"
      >
        {measurementOptions.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Field>
      <Field
        name={`recipeIngredients.[${fieldArrayIndex}].measurementType`}
        component="select"
        className="mt-2 ml-2 mb-1 text-base relative border rounded placeholder-gray-400 focus:border-indigo-400 focus:outline-none"
      >
        <option value="oz">OZ</option>
        <option value="part">Part</option>
        <option value="none">No Unit</option>
        <option value="dash">Dash</option>
        <option value="barspoon">Barspoon</option>
      </Field>
    </div>
  );
};

export default MeasurementInputs;
